import * as io from 'socket.io-client';

import { Observable, from } from 'rxjs';

export class SocketIOService {
    private url = '/';
    private socket;
    private websocket;
    private connected = false;
    public connectedusers: any;

    constructor() {
        //this.socket = io('wss://wwt6zbnio7.execute-api.ap-southeast-2.amazonaws.com/production');
        this.websocket = new WebSocket('wss://wwt6zbnio7.execute-api.ap-southeast-2.amazonaws.com/production');
        console.log(this.websocket.readyState);
        this.websocket.onopen = function () {
            alert("connected");
        }

    }
    SetUserName2(inComingUsername) {
        var ex = '{ "action": "add user", "username": "' + inComingUsername + '", "busy":false }';
        this.websocket.send(ex);
    }
    InitListener() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                if (event != null && event.data != null && event.data.length > 0) {
                    try {
                        console.log("INCOMING MESSAGE");
                        console.log(event);
                        var data = JSON.parse(event.data);
                        observer.next(data);
                    }
                    catch (exc) {
                        console.log("EXCEPTION");
                        console.log(exc);
                    }
                }
            }
        });
    }
    SetUserName(username) {
        this.socket.emit('add user', username);
        /*return Observable.create((observer) => {
            this.socket.on('logged-user', (data) => {
                this.connected = true;
                observer.next(data.data);
            });
        });*/
    }
    public RemoveUser() {
        var ex = '{ "action": "disconnect" }';
        this.websocket.send(ex);
        //this.socket.emit('disconnect');
    }

    public BroadCastMessage(message) {
        var ex = '{ "action": "new-broadcast-message", "message":"' + message + '"}';
        this.websocket.send(ex);
        /*
        this.socket.emit('new-broadcast-message', message);*/
    }

    public SendMessage(message, from, to) {
        /*this.socket.emit('new-message', message);
        this.socket.emit('new-message', {
            toid: to,
            message: message,
            fromname: from
        });
        */
        var ex = '{ "action": "new-message", "fromname": "' + from + '", "toid":"' + to + '", "message":"' + message + '"}';
        this.websocket.send(ex);
    }

    public WebSocketTest() {
        this.websocket.send('connect')
        //this.socket.emit('connect');
    }

    /*
    public GetMessages() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "new-message":
                        alert("new-message");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });
    }*/
    public GetConnectedUsers() {
        try {
            var ex = '{ "action": "client-list"}';
            this.websocket.send(ex);
        }
        catch (EXC) {
            console.log("Get Connected Users - EXCEPTION");
            console.log(EXC);
        }
        /*return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "client-list":
                        alert("GetConnectedUsers2 - 4");
                        observer.next(data.data);
                        break;
                }
            }  
        });*/
    
    }
    /***
     * Section Video call
     * following requests are used for video call
     */

    public VideoCallRequest(from, to) {
        var ex = '{ "action": "video-call", "fromname": "' + from + '", "toid":"' + to + '"}';
        this.websocket.send(ex);
        /*
        this.socket.emit('video-call', {
            fromname: from,
            toid: to
        });*/
    }
    public VideoCallRequest2(from, to) {
        var ex = '{ "action": "video-call", "fromname": "' + from + '", "toid":"' + to + '"}';
        this.websocket.send(ex); 
    }
   /*
    public OnVideoCallRequest2() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "video-call":
                        alert("video-list");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }  
        });
    }
    public OnVideoCallRequest() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "video-call":
                        alert("video-list");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });
    }*/
    public VideoCallAccepted2(from, to) {
        var ex = '{ "action": "video-call-accept", "fromname": "' + from + '", "toid":"' + to + '"}';
        this.websocket.send(ex); 
    }
    public VideoCallAccepted(from, to) {
        var ex = '{ "action": "video-call-accept", "fromname": "' + from + '", "toid":"' + to + '"}';
        this.websocket.send(ex);
}
/*
    public OnVideoCallAccepted() {
        return Observable.create((observer) => {
            this.socket.on('video-call-accept', (data) => {
                observer.next(data);
            });
        });
    }
    public OnVideoCallAccepted2() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "video-call-accept":
                        alert("video-call-accept");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }  
        });
    }*/
    public BusyNow() {
        var ex = '{ "action": "busy-user"}';
        this.websocket.send(ex); 
        //this.socket.emit('busy-user');
    }
    public BusyNow2() {
        var ex = '{ "action": "busy-user"}';
        this.websocket.send(ex); 
    }
    public GetBusyUsers2() {
        var ex = '{ "action": "get-busy-user"}';
        this.websocket.send(ex); 
        /*return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "get-busy-user":
                        alert("get-busy-user");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });*/
    }
    public GetBusyUsers() {
        var ex = '{ "action": "get-busy-user"}';
        this.websocket.send(ex);
        /*return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "get-busy-user":
                        alert("get-busy-user");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });*/
    }
    public EndVideoCall(from, to, toname) {
        var ex = '{ "action": "end-video-call", "from":"' + from + '", "to":"' + to + '", "toname":"' + toname + '"}';
        this.websocket.send(ex);
        /*
        this.socket.emit('end-video-call', {
            fromname: from,
            toid: to,
            toname: toname
        });*/
    }
    public OnVideoCallEnded() {
        /*this.socket.emit('get-busy-user');
        return Observable.create((observer) => {
            this.socket.on('video-call-ended', (data) => {
                observer.next(data);
            });
        });*/
        var ex = '{ "action": "get-busy-user"}';
        this.websocket.send(ex);
        /*return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "video-call-ended":
                        alert("video-call-ended");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });*/
    }
    public VideoCallRejected(from, to) {
        var ex = '{ "video-call-reject": "from":"' + from + '", "to":"' + to + '"}';
        this.websocket.send(ex);
        /*this.socket.emit('video-call-reject', {
            fromname: from,
            toid: to
        });*/
}
/*
    public OnVideoCallRejected() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "video-call-reject":
                        alert("video-call-reject");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });
    }*/
    /**
     * 
     * @param candidate or @param description for video call
     * need to send remote user id
     */
    public SendCallRequest(val, type, uid) {
        console.log("SendCallRequest");
        var data;
        if (type == 'desc') {
            data = {
                toid: uid,
                desc: val
            }
        } else {
            data = {
                toid: uid,
                candidate: val
            }
        }
        var ex = '{ "call-request": "desc":' + JSON.stringify(data) + '}';
        this.websocket.send(ex);
    }
/*
    public ReceiveCallRequest() {
        return Observable.create((observer) => {
            this.websocket.onmessage = function (event) {
                var data = JSON.parse(event.data);
                switch (data.action) {
                    case "call-request":
                        alert("call-request");
                        observer.next(data.data);
                        break;
                }
                alert("rec message");
            }
        });
    }*/
}