import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SocketIOService } from '../services/socket.io.service';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
    public loggedUserName;
    public isChat = false;
    public isVideoCall = false;
    public isAudioCall = false;
    public liveUserList = [];
    public callee: any;
    public callingInfo = { name: "", content: "", type: "" };
    public isVideoCallAccepted: boolean = false;
    public userType: string;
    public caller: any;

    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private socketIOService: SocketIOService) {
        this.loggedUserName = sessionStorage.getItem("username");
        if (!this.loggedUserName) {
            this.router.navigate(['/Login']);
        } else {
            this.AddUser();
        }
    }

    ngOnInit() {
        this.InitListener();
        this.GetLiveUsers();
        //setTimeout(this.GetLiveUsers, 5000);
        
        /*this.OnVideoCallRequest();
        this.OnVideoCallAccepted();
        this.GetBusyUsers();
        this.OnVideoCallRejected();*/
    }

    AddUser() {
        this.socketIOService.SetUserName2(this.loggedUserName);
    }

    InitListener() {
        this.socketIOService
            .InitListener()
            .subscribe(data => {
                console.log("incoming message");
                console.log(data);
                switch (data.action) {
                    case "new-message":
                        
                        break;
                    case "client-list":
                        
                        this.ListLiveUsers(data.data);
                        break;
                    case "video-call":
                        this.SetupCall(data)
                        break;
                    case "video-call-accept":
                        this.AcceptCall(data);
                        break;
                    case "get-busy-user":
                        this.UpdateBusyUsers(data.data);
                        break;
                    case "video-call-ended":
                        this.CallEnded(data);
                        break;
                    case "video-call-reject":
                        this.CallRejected(data);
                        break;
                }
                
            });
    }
    CallRejected(data) {
        this.callingInfo.content = "Call Rejected ..";
        setTimeout(() => {
            this.Close();
        }, 1000);
    }
    CallEnded(data) {
        this.callingInfo.content = "Call Rejected ..";
        setTimeout(() => {
            this.Close();
        }, 1000);
    }
    UpdateBusyUsers(data) {
        this.liveUserList.forEach(a => { a.busy = false; });
        data.forEach(a => {
            var usr = this.liveUserList.find(b => b.username == a.username);
            if (usr) {
                usr.busy = true;
            }
        });
    }
    AcceptCall(data) {
        var calee = this.liveUserList.find(a => a.username == this.callingInfo.name);
        this.userType = "dialer";
        this.caller = calee.connectionId;
        this.isVideoCallAccepted = true;
        this.socketIOService.BusyNow();
        this.Close();
    }

    SetupCall(data) {
        this.callingInfo.name = data.fromname;
        this.callingInfo.content = "Calling....";
        this.callingInfo.type = "receiver";
        this.isVideoCall = true;
    }

    ListLiveUsers(data) {
        alert("here!!!!!!!");
        console.log(data);
        var users = data.filter(a => a.username != this.loggedUserName);
        var count = 0;
        for (var i in users) {
            if (this.liveUserList.indexOf(data[i]) === -1) {
                count++;
            }
        }
        if (count != this.liveUserList.length) {
            this.liveUserList = users;
            this.socketIOService.connectedusers = users;
            this.GetBusyUsers();
        }
    }


    GetLiveUsers() {
        try {
            console.log("Get LiveUsers Called");
            this.socketIOService.GetConnectedUsers();
        }
        catch (Exc) {
            console.log("EXCEP");
            console.log(Exc);
        }
            /*.subscribe(data => {
                alert("here!!!!!!!");
                console.log(data);
                var users = data.filter(a => a.username != this.loggedUserName);
                var count = 0;
                for (var i in users) {
                    if (this.liveUserList.indexOf(data[i]) === -1) {
                        count++;
                    }
                }
                if (count != this.liveUserList.length) {
                    this.liveUserList = users;
                    this.socketIOService.connectedusers = users;
                    this.GetBusyUsers();
                }
            });*/
    }

    WebSocketTest() {
        this.GetLiveUsers();
        /*this.socketIOService
            .WebSocketTest()
            .subscribe(data => {

                alert(data);

            });*/
    }
    /*
    OnVideoCallRequest() {
        this.socketIOService
            .OnVideoCallRequest2();
            .subscribe(data => {
                this.callingInfo.name = data.fromname;
                this.callingInfo.content = "Calling....";
                this.callingInfo.type = "receiver";
                this.isVideoCall = true;
            });
    }
    OnVideoCallAccepted() {
        this.socketIOService
            .OnVideoCallAccepted2();
            .subscribe(data => {
                var calee = this.liveUserList.find(a => a.username == this.callingInfo.name);
                this.userType = "dialer";
                this.caller = calee.id;
                this.isVideoCallAccepted = true;
                this.socketIOService.BusyNow();
                this.Close();
            });
    }*/
    GetBusyUsers() {
        this.socketIOService
            .GetBusyUsers();
            /*.subscribe(data => {
                this.liveUserList.forEach(a => { a.busy = false; });
                data.forEach(a => {
                    var usr = this.liveUserList.find(b => b.username == a.username);
                    if (usr) {
                        usr.busy = true;
                    }
                });
            })*/
    }
    /*
    OnVideoCallRejected() {
        this.socketIOService
            .OnVideoCallRejected();
            .subscribe(data => {
                this.callingInfo.content = "Call Rejected ..";
                setTimeout(() => {
                    this.Close();
                }, 1000);
            });
    }*/
    Chat() {
        this.isChat = true;
    }

    VideoCall(callee) {
        console.log("CALLEE");
        console.log(callee);
        console.log(this.liveUserList);
        var calee = this.liveUserList.find(a => a.username == callee.username);
        if (calee) {
            this.socketIOService.VideoCallRequest2(this.loggedUserName, calee.connectionId);
        }
        this.callee = callee;
        this.callingInfo.name = callee.username;
        this.callingInfo.content = "Dialing....";
        this.callingInfo.type = "dialer";
        this.isVideoCall = true;
    }

    AcceptVideoCall() {
        console.log("ACEPT");
        console.log(this.liveUserList);
        console.log(this.callingInfo);
        var calee = this.liveUserList.find(a => a.username == this.callingInfo.name);
        if (calee) {
            this.socketIOService.VideoCallAccepted(this.loggedUserName, calee.connectionId);
            this.userType = "receiver";
            this.caller = calee.id;
            this.isVideoCallAccepted = true;
            this.socketIOService.BusyNow();
        }
        this.Close();
    }

    RejectVideoCall() {
        var calee = this.liveUserList.find(a => a.username == this.callingInfo.name);
        if (calee) {
            this.socketIOService.VideoCallRejected(this.loggedUserName, calee.id);
            this.isVideoCallAccepted = false;
        }
        this.Close();
    }
    AudioCall() {
        this.isAudioCall = true;
    }

    CallBack(event) {
        this.isChat = false;
        this.isVideoCall = false;
        this.isAudioCall = false;
        this.isVideoCallAccepted = false;
        this.changeDetector.detectChanges();
        location.reload();
    }

    Close() {
        this.isVideoCall = false;
        this.changeDetector.detectChanges();
    }
    Logout() {
        this.socketIOService.RemoveUser();
        sessionStorage.clear();
        location.reload();
    }
}
